<template>
    <div>
        <b-row>
            <b-col sm="5">
                <b-card>
                    <b-row>
                        <b-col cols="12" class="my-1">
                            <h5>CANTIDAD DE PROVEEDORES REGISTRADOS POR RUBRO</h5>    
                        </b-col>                    
                        <b-col cols="12">
                            <vue-apex-charts
                                ref="pieThreeRef"
                                type="pie"
                                height="325"
                                class="mt-2 mb-1"
                                :options="tablePieThree.chartOptions"
                                :series="tablePieThree.series"
                            />                            
                        </b-col>                    
                    </b-row>
                </b-card>                                           
            </b-col>
            <b-col sm="7">
                <b-card>                     
                    <json-excel :data="threeTableExport()" name="cantidad_proveedores_registrados_por_rubro.xls"></json-excel>
                    <b-table
                        ref="table-three-ref"
                        :fields="fieldsThree"
                        :items="itemsThree"
                        show-empty
                        empty-text="No se encontraron datos."
                    >   

                        <template #cell(business_area_name)="data">
                            <b class="text-primary" v-if="data.item.status">{{ data.item.business_area_name }}</b>
                            <span v-else>{{ data.item.business_area_name }}</span>                         
                        </template>
                    
                        <template #cell(quantity)="data">
                            <b class="text-primary" v-if="data.item.status">{{ data.item.quantity }}</b>
                            <span v-else>{{ data.item.quantity }}</span>                        
                        </template>
                        <template #cell(percentage)="data">
                            <b class="text-primary" v-if="data.item.status">{{ data.item.percentage }}</b>
                            <span v-else>{{ data.item.percentage }}</span>
                        </template>
                    
                    </b-table>
                </b-card>                
            </b-col>
        </b-row>
        <b-row>
            <b-col sm="5">
                <b-card>
                    <b-row>
                        <b-col cols="12" class="my-1">
                            <h5>CANTIDAD DE FORMULARIOS POR NIVEL OBTENIDO (RANGO DE PUNTAJES)</h5>    
                        </b-col>
                        <b-col sm="12">
                            <b-row class="text-center">
                                <b-col sm="4" md="2" class="my-auto">                                    
                                    <feather-icon icon="CalendarIcon" size="1.5x" class="mr-1" style="color: rgba(216, 164, 0, 0.85);"  /> De
                                </b-col>
                                <b-col sm="8" md="3" class="my-auto">                                    
                                    <flat-pickr
                                        v-model="starDateQuinto"
                                        class="form-control"
                                        :config="configCalendar"
                                    />        
                                </b-col>
                                <b-col sm="4" md="1" class="my-auto"> 
                                    a
                                </b-col>
                                <b-col sm="8" md="3" class="my-auto">
                                    <flat-pickr
                                        v-model="endDateQuinto"
                                        class="form-control"
                                        :config="configCalendar"
                                    />        
                                </b-col>    
                                <b-col sm="12" md="3" class="my-auto"> 
                                   <b-button
                                        size="sm"
                                        variant="primary"
                                        @click="filterQuinto"
                                    >                                    
                                     <feather-icon icon="FilterIcon" size="1x"/>   
                                       Filtrar
                                   </b-button>
                                </b-col>                            
                            </b-row>                            
                        </b-col>                 
                        <b-col cols="12">
                            <vue-apex-charts
                                ref="pieQuintoRef"
                                type="bar"
                                height="325"
                                class="mt-2 mb-1"
                                :options="tablePieQuinto.chartOptions"
                                :series="tablePieQuinto.series"
                            />                            
                        </b-col>                    
                    </b-row>
                </b-card>                                           
            </b-col>
            <b-col sm="7">
                <b-card>                     
                    <json-excel :data="quintoTableExport()" name="cantidad_formularios_por_nivel_obtenido.xls"></json-excel>
                    <b-table
                        ref="table-quinto-ref"
                        :fields="fieldsQuinto"
                        :items="itemsQuinto"
                        show-empty
                        empty-text="No se encontraron datos."
                    >   

                        <template #cell(level_name)="data">
                            <b class="text-primary" v-if="data.item.status">{{ data.item.level_name }}</b>
                            <span v-else>{{ data.item.level_name }}</span>                       
                        </template>
                    
                        <template #cell(quantity)="data">
                            <b class="text-primary" v-if="data.item.status">{{ data.item.quantity }}</b>
                            <span v-else>{{ data.item.quantity }}</span>
                        </template>
                        <template #cell(percentage)="data">
                            <b class="text-primary" v-if="data.item.status">{{ data.item.percentage }}</b>
                            <span v-else>{{ data.item.percentage }}</span>                       
                        </template>
                    
                    </b-table>
                </b-card>                
            </b-col>
        </b-row>
        <b-row>
            <b-col sm="5">
                <b-card>
                    <b-row>
                        <b-col cols="12" class="my-1">
                            <h5>CANTIDAD DE FORMULARIOS POR ESTADO</h5>    
                        </b-col>                    
                        <b-col sm="12">
                            <b-row class="text-center">
                                <b-col sm="4" md="2" class="my-auto">                                    
                                    <feather-icon icon="CalendarIcon" size="1.5x" class="mr-1" style="color: rgba(216, 164, 0, 0.85);"  /> De
                                </b-col>
                                <b-col sm="8" md="3" class="my-auto">                                    
                                    <flat-pickr
                                        v-model="starDateQuarter"
                                        class="form-control"
                                        :config="configCalendar"
                                    />        
                                </b-col>
                                <b-col sm="4" md="1" class="my-auto"> 
                                    a
                                </b-col>
                                <b-col sm="8" md="3" class="my-auto">
                                    <flat-pickr
                                        v-model="endDateQuarter"
                                        class="form-control"
                                        :config="configCalendar"
                                    />        
                                </b-col>    
                                <b-col sm="12" md="3" class="my-auto"> 
                                   <b-button
                                        size="sm"
                                        variant="primary"
                                        @click="filterQuarter"
                                    >                                    
                                     <feather-icon icon="FilterIcon" size="1x"/>   
                                       Filtrar
                                   </b-button>
                                </b-col>                            
                            </b-row>                            
                        </b-col>
                        <b-col cols="12">
                            <vue-apex-charts
                                ref="pieQuarterRef"
                                type="pie"
                                height="325"
                                class="mt-2 mb-1"
                                :options="tablePieQuarter.chartOptions"
                                :series="tablePieQuarter.series"
                            />                            
                        </b-col>                    
                    </b-row>
                </b-card>                                           
            </b-col>
            <b-col sm="7">  
                <b-card>                     
                    <json-excel :data="quarterTableExport()" name="cantidad_formularios_por_estado.xls"></json-excel>
                    <b-table
                        ref="table-quarter-ref"
                        :fields="fieldsQuarter"
                        :items="itemsQuarter"
                        show-empty
                        empty-text="No se encontraron datos."
                    >   
                             
                        <template #cell(state_form_name)="data">
                            <b class="text-primary" v-if="data.item.status">{{ data.item.state_form_name }}</b>
                            <span v-else>{{ data.item.state_form_name }}</span>                         
                        </template>
                    
                        <template #cell(quantity)="data">
                            <b class="text-primary" v-if="data.item.status">{{ data.item.quantity }}</b>           
                            <span v-else>{{ data.item.quantity }}</span>
                        </template>
                        <template #cell(percentage)="data">
                            <b class="text-primary" v-if="data.item.status">{{ data.item.percentage }}</b>                       
                            <span v-else>{{ data.item.percentage }}</span>
                        </template>
                    
                    </b-table>
                </b-card>                
            </b-col>
        </b-row>
        <b-row>
            <b-col sm="12">  
                <b-card>

                    <b-row class="text-center mb-2">
                        <b-col sm="4" md="2" class="my-auto">                                    
                            <feather-icon icon="CalendarIcon" size="1.5x"  class="mr-1" style="color: rgba(216, 164, 0, 0.85);"  />       
                            De
                        </b-col>
                        <b-col sm="8" md="3" class="my-auto">                                    
                            <flat-pickr
                                v-model="starDateOne"
                                class="form-control"
                                :config="configCalendar"
                            />        
                        </b-col>
                        <b-col sm="4" md="1" class="my-auto"> 
                            a
                        </b-col>
                        <b-col sm="8" md="3" class="my-auto">
                            <flat-pickr
                                v-model="endDateOne"
                                class="form-control"
                                :config="configCalendar"
                            />        
                        </b-col>    
                        <b-col sm="12" md="3" class="my-auto"> 
                            <b-button
                                size="sm"
                                variant="primary"
                                @click="filterOne"
                            >                                    
                                <feather-icon icon="FilterIcon" size="1x"/>   
                                Filtrar
                            </b-button>
                        </b-col>                            
                    </b-row> 

                    <!-- sorting  -->
                    <b-form-group
                        label="Ordenar"
                        label-size="sm"
                        label-align-sm="left"
                        label-cols-sm="2"
                        label-for="sortBySelect1"
                        class="mr-1 mb-md-0"
                    >
                        <b-input-group
                            size="sm"
                        >
                            <b-form-select
                                id="sortBySelect1"
                                v-model="sortBy1"
                                :options="sortOption1"
                            >
                                <template #first>
                                    <option value="">
                                        Ninguno
                                    </option>
                                </template>
                            </b-form-select>
                            <b-form-select
                                v-model="sortDesc1"
                                size="sm"
                                :disabled="!sortBy1"
                            >
                                <option :value="false">
                                    Asc
                                </option>
                                <option :value="true">
                                    Desc
                                </option>
                            </b-form-select>
                        </b-input-group>
                    </b-form-group>

                    <!-- filter -->
                    <b-form-group
                        label="Fitrar"
                        label-cols-sm="2"
                        label-align-sm="left"
                        label-size="sm"
                        label-for="filterInput1"
                        class="mt-1"
                    >
                        <b-input-group size="sm">
                            <b-form-input
                                id="filterInput1"
                                v-model="filter1"
                                type="search"
                                placeholder="Buscar ..."
                            />
                            <b-input-group-append>
                                <b-button
                                    :disabled="!filter1"
                                    @click="filter1 = ''"
                                >
                                    Limpiar
                                </b-button>
                            </b-input-group-append>
                        </b-input-group>
                    </b-form-group>
                                    
                    <json-excel :data="firstTableExport()" name="cantidad_de_formularios_por_rubro_y_por_proceso.xls"></json-excel>
                    <b-table
                        ref="table1Ref"
                        :fields="fields1"
                        :items="items1"
                        show-empty
                        empty-text="NO HAY DATA DISPONIBLE."
                        striped
                        hover
                        responsive
                        class="position-relative text-center"
                        :per-page="perPage1"
                        :current-page="currentPage1"
                        :sort-by.sync="sortBy1"
                        :sort-desc.sync="sortDesc1"
                        :sort-direction="sortDirection1"
                        :filter="filter1"
                        :filter-included-fields="filterOn1"
                        @filtered="onFiltered1"
                    >   
                        <template #head()="data">
                            <div v-html="data.label"></div>
                        </template>

                        <template #cell(id)="data">
                            <span class="text-nowrap" v-if="data.item.business_area_name == 'TOTAL'"> </span>
                            <span class="text-nowrap" v-else>
                                {{ data.index+1 }}
                            </span>
                        </template>
                        
                        <template #cell(business_area_name)="data">
                            <b class="text-primary" v-if="data.item.status">{{ data.item.business_area_name }}</b>
                            <span v-else>{{ data.item.business_area_name }}</span>                       
                        </template>

                        <template #cell(total)="data">
                            <b class="text-primary" v-if="data.item.status">{{ data.item.total }}</b>
                            <span v-else>{{ data.item.total }}</span>                       
                        </template>
                    
                        <template #cell(completado_cantidad)="data">
                            <b class="text-primary" v-if="data.item.status">{{ data.item.completado_cantidad }}</b>
                            <span v-else>{{ data.item.completado_cantidad }}</span>                       
                        </template>

                        <template #cell(en_revisión_cantidad)="data">
                            <b class="text-primary" v-if="data.item.status">{{ data.item.en_revisión_cantidad }}</b>
                            <span v-else>{{ data.item.en_revisión_cantidad }}</span>                       
                        </template>

                        <template #cell(no_realizado_cantidad)="data">
                            <b class="text-primary" v-if="data.item.status">{{ data.item.no_realizado_cantidad }}</b>
                            <span v-else>{{ data.item.no_realizado_cantidad }}</span>                       
                        </template>

                        <template #cell(en_curso_cantidad)="data">
                            <b class="text-primary" v-if="data.item.status">{{ data.item.en_curso_cantidad }}</b>
                            <span v-else>{{ data.item.en_curso_cantidad }}</span>                       
                        </template>
                        
                        <template #cell(por_realizar_cantidad)="data">
                            <b class="text-primary" v-if="data.item.status">{{ data.item.por_realizar_cantidad }}</b>
                            <span v-else>{{ data.item.por_realizar_cantidad }}</span>                       
                        </template>
                    
                    </b-table>
                </b-card>                
            </b-col>
        </b-row>
        <b-row>
            <b-col sm="12">                
                <b-card>         
                    <b-row class="text-center mb-2">
                        <b-col sm="4" md="2" class="my-auto">                                    
                            <feather-icon icon="CalendarIcon" size="1.5x"  class="mr-1" style="color: rgba(216, 164, 0, 0.85);"  />       
                            De
                        </b-col>
                        <b-col sm="8" md="3" class="my-auto">                                    
                            <flat-pickr
                                v-model="starDateTwo"
                                class="form-control"
                                :config="configCalendar"
                            />        
                        </b-col>
                        <b-col sm="4" md="1" class="my-auto"> 
                            a
                        </b-col>
                        <b-col sm="8" md="3" class="my-auto">
                            <flat-pickr
                                v-model="endDateTwo"
                                class="form-control"
                                :config="configCalendar"
                            />        
                        </b-col>    
                        <b-col sm="12" md="3" class="my-auto"> 
                            <b-button
                                size="sm"
                                variant="primary"
                                @click="filterTwo"
                            >                                    
                                <feather-icon icon="FilterIcon" size="1x"/>   
                                Filtrar
                            </b-button>
                        </b-col>                            
                    </b-row> 

                    <!-- sorting  -->
                    <b-form-group
                        label="Ordenar"
                        label-size="sm"
                        label-align-sm="left"
                        label-cols-sm="2"
                        label-for="sortBySelect2"
                        class="mr-1 mb-md-0"
                    >
                        <b-input-group
                            size="sm"
                        >
                            <b-form-select
                                id="sortBySelect2"
                                v-model="sortBy2"
                                :options="sortOption2"
                            >
                                <template #first>
                                    <option value="">
                                        Ninguno
                                    </option>
                                </template>
                            </b-form-select>
                            <b-form-select
                                v-model="sortDesc2"
                                size="sm"
                                :disabled="!sortBy2"
                            >
                                <option :value="false">
                                    Asc
                                </option>
                                <option :value="true">
                                    Desc
                                </option>
                            </b-form-select>
                        </b-input-group>
                    </b-form-group>

                    <!-- filter -->
                    <b-form-group
                        label="Fitrar"
                        label-cols-sm="2"
                        label-align-sm="left"
                        label-size="sm"
                        label-for="filterInput2"
                        class="mt-1"
                    >
                        <b-input-group size="sm">
                            <b-form-input
                                id="filterInput2"
                                v-model="filter2"
                                type="search"
                                placeholder="Buscar ..."
                            />
                            <b-input-group-append>
                                <b-button
                                    :disabled="!filter2"
                                    @click="filter2 = ''"
                                >
                                    Limpiar
                                </b-button>
                            </b-input-group-append>
                        </b-input-group>
                    </b-form-group>
                            
                    <json-excel :data="secondTableExport()" name="cantidad_de_informes_emitidos_por_rubros.xls"></json-excel>
                    <b-table
                        ref="table2Ref"
                        :fields="fields2"
                        :items="items2"
                        show-empty
                        empty-text="NO HAY DATA DISPONIBLE."
                        striped
                        hover
                        responsive
                        class="position-relative text-center"
                        :per-page="perPage2"
                        :current-page="currentPage2"
                        :sort-by.sync="sortBy2"
                        :sort-desc.sync="sortDesc2"
                        :sort-direction="sortDirection2"
                        :filter="filter2"
                        :filter-included-fields="filterOn2"
                        @filtered="onFiltered2"
                    >               
                        <template #head()="data">
                            <div v-html="data.label"></div>
                        </template>

                        <template #cell(id)="data">
                            <span class="text-nowrap" v-if="data.item.business_area_name == 'TOTAL'"> </span>
                            <span class="text-nowrap" v-else>
                                {{ data.index+1 }}
                            </span>
                        </template>
                                                 
                        <template #cell(business_area_name)="data">
                            <b class="text-primary" v-if="data.item.status">{{ data.item.business_area_name }}</b>
                            <span v-else>{{ data.item.business_area_name }}</span>                       
                        </template>

                        <template #cell(total)="data">
                            <b class="text-primary" v-if="data.item.status">{{ data.item.total }}</b>
                            <span v-else>{{ data.item.total }}</span>                       
                        </template>
                        
                        <template #cell(percentage)="data">
                            <b class="text-primary" v-if="data.item.status">{{ data.item.percentage }}</b>
                            <span v-else>{{ data.item.percentage}}</span>                       
                        </template>
                        
                    </b-table>
                </b-card>                
            </b-col>
        </b-row>
        <b-row>
            <b-col xs="12">
                <b-card>

                    <b-row class="text-center mb-2">
                        <b-col sm="4" md="2" class="my-auto">                                    
                            <feather-icon icon="CalendarIcon" size="1.5x"  class="mr-1" style="color: rgba(216, 164, 0, 0.85);"  />       
                            De
                        </b-col>
                        <b-col sm="8" md="3" class="my-auto">                                    
                            <flat-pickr
                                v-model="starDateSix"
                                class="form-control"
                                :config="configCalendar"
                            />        
                        </b-col>
                        <b-col sm="4" md="1" class="my-auto"> 
                            a
                        </b-col>
                        <b-col sm="8" md="3" class="my-auto">
                            <flat-pickr
                                v-model="endDateSix"
                                class="form-control"
                                :config="configCalendar"
                            />        
                        </b-col>    
                        <b-col sm="12" md="3" class="my-auto"> 
                            <b-button
                                size="sm"
                                variant="primary"
                                @click="filterSix"
                            >                                    
                                <feather-icon icon="FilterIcon" size="1x"/>   
                                Filtrar
                            </b-button>
                        </b-col>                            
                    </b-row> 

                    <!-- sorting  -->
                    <b-form-group
                        label="Ordenar"
                        label-size="sm"
                        label-align-sm="left"
                        label-cols-sm="2"
                        label-for="sortBySelect3"
                        class="mr-1 mb-md-0"
                    >
                        <b-input-group
                            size="sm"
                        >
                            <b-form-select
                                id="sortBySelect3"
                                v-model="sortBy3"
                                :options="sortOptions3"
                            >
                                <template #first>
                                    <option value="">
                                        Ninguno
                                    </option>
                                </template>
                            </b-form-select>
                            <b-form-select
                                v-model="sortDesc3"
                                size="sm"
                                :disabled="!sortBy3"
                            >
                                <option :value="false">
                                    Asc
                                </option>
                                <option :value="true">
                                    Desc
                                </option>
                            </b-form-select>
                        </b-input-group>
                    </b-form-group>

                    <!-- filter -->
                    <b-form-group
                        label="Fitrar"
                        label-cols-sm="2"
                        label-align-sm="left"
                        label-size="sm"
                        label-for="filterInput3"
                        class="mt-1"
                    >
                        <b-input-group size="sm">
                            <b-form-input
                                id="filterInput3"
                                v-model="filter3"
                                type="search"
                                placeholder="Buscar ..."
                            />
                            <b-input-group-append>
                                <b-button
                                    :disabled="!filter3"
                                    @click="filter3 = ''"
                                >
                                    Limpiar
                                </b-button>
                            </b-input-group-append>
                        </b-input-group>
                    </b-form-group>

                    <json-excel :data="sixTableExport()" name="cantidad_de_formularios_completados_por_rubro.xls"></json-excel>
                    <b-table
                        ref="table3"
                        striped
                        hover
                        responsive
                        class="position-relative text-center"
                        :per-page="perPage3"
                        :current-page="currentPage3"
                        :items="items3"
                        :fields="fields3"
                        :sort-by.sync="sortBy3"
                        :sort-desc.sync="sortDesc3"
                        :sort-direction="sortDirection3"
                        :filter="filter3"
                        :filter-included-fields="filterOn3"
                        @filtered="onFiltered3"
                        show-empty
                    >

                        <template #head()="data">
                            <div v-html="data.label"></div>
                        </template>

                        <template #empty="scope">
                            NO HAY DATA DISPONIBLE
                        </template>

                        <template #cell(id)="data">
                            <span class="text-nowrap" v-if="data.item.business_area_name == 'TOTAL'"> </span>
                            <span class="text-nowrap" v-else>
                                {{ data.index+1 }}
                            </span>
                        </template>

                        <template #cell(business_area_name)="data">
                            <b class="text-primary" v-if="data.item.status">{{ data.item.business_area_name }}</b>
                            <span v-else>{{ data.item.business_area_name }}</span>                       
                        </template>

                        <template #cell(total)="data">
                            <b class="text-primary" v-if="data.item.status">{{ data.item.total }}</b>
                            <span v-else>{{ data.item.total }}</span>                       
                        </template>

                        <template #cell(alto_cantidad)="data">
                            <b class="text-primary" v-if="data.item.status">{{ data.item.alto_cantidad }}</b>
                            <span v-else>{{ data.item.alto_cantidad }}</span>                       
                        </template>
                        
                        <template #cell(medio_cantidad)="data">
                            <b class="text-primary" v-if="data.item.status">{{ data.item.medio_cantidad }}</b>
                            <span v-else>{{ data.item.medio_cantidad }}</span>                       
                        </template>
                        
                        <template #cell(bajo_cantidad)="data">
                            <b class="text-primary" v-if="data.item.status">{{ data.item.bajo_cantidad }}</b>
                            <span v-else>{{ data.item.bajo_cantidad }}</span>                       
                        </template>
                        <!-- 
                        <template #cell(sobresaliente_cantidad)="data">
                            <b class="text-primary" v-if="data.item.status">{{ data.item.sobresaliente_cantidad }}</b>
                            <span v-else>{{ data.item.sobresaliente_cantidad }}</span>                       
                        </template>

                        <template #cell(bueno_cantidad)="data">
                            <b class="text-primary" v-if="data.item.status">{{ data.item.bueno_cantidad }}</b>
                            <span v-else>{{ data.item.bueno_cantidad }}</span>                       
                        </template>

                        <template #cell(regular_cantidad)="data">
                            <b class="text-primary" v-if="data.item.status">{{ data.item.regular_cantidad }}</b>
                            <span v-else>{{ data.item.regular_cantidad }}</span>                       
                        </template>

                        <template #cell(requiere_mejorar_cantidad)="data">
                            <b class="text-primary" v-if="data.item.status">{{ data.item.requiere_mejorar_cantidad }}</b>
                            <span v-else>{{ data.item.requiere_mejorar_cantidad }}</span>                       
                        </template>
                        
                        <template #cell(no_apto_cantidad)="data">
                            <b class="text-primary" v-if="data.item.status">{{ data.item.no_apto_cantidad }}</b>
                            <span v-else>{{ data.item.no_apto_cantidad }}</span>                       
                        </template>
                        -->
    
                    </b-table>

                </b-card>
            </b-col>
        </b-row>
        <b-row>
            <b-col xs="12">
                <b-card>

                    <b-row class="text-center mb-2">
                        <b-col sm="4" md="2" class="my-auto">                                    
                            <feather-icon icon="CalendarIcon" size="1.5x"  class="mr-1" style="color: rgba(216, 164, 0, 0.85);"  />       
                            De
                        </b-col>
                        <b-col sm="8" md="3" class="my-auto">                                    
                            <flat-pickr
                                v-model="starDateSeven"
                                class="form-control"
                                :config="configCalendar"
                            />        
                        </b-col>
                        <b-col sm="4" md="1" class="my-auto"> 
                            a
                        </b-col>
                        <b-col sm="8" md="3" class="my-auto">
                            <flat-pickr
                                v-model="endDateSeven"
                                class="form-control"
                                :config="configCalendar"
                            />        
                        </b-col>    
                        <b-col sm="12" md="3" class="my-auto"> 
                            <b-button
                                size="sm"
                                variant="primary"
                                @click="filterSeven"
                            >                                    
                                <feather-icon icon="FilterIcon" size="1x"/>   
                                Filtrar
                            </b-button>
                        </b-col>                            
                    </b-row>

                    <!-- sorting  -->
                    <b-form-group
                        label="Ordenar"
                        label-size="sm"
                        label-align-sm="left"
                        label-cols-sm="2"
                        label-for="sortBySelect4"
                        class="mr-1 mb-md-0"
                    >
                        <b-input-group
                            size="sm"
                        >
                            <b-form-select
                                id="sortBySelect4"
                                v-model="sortBy4"
                                :options="sortOptions4"
                            >
                                <template #first>
                                    <option value="">
                                        Ninguno
                                    </option>
                                </template>
                            </b-form-select>
                            <b-form-select
                                v-model="sortDesc4"
                                size="sm"
                                :disabled="!sortBy4"
                            >
                                <option :value="false">
                                    Asc
                                </option>
                                <option :value="true">
                                    Desc
                                </option>
                            </b-form-select>
                        </b-input-group>
                    </b-form-group>

                    <!-- filter -->
                    <b-form-group
                        label="Fitrar"
                        label-cols-sm="2"
                        label-align-sm="left"
                        label-size="sm"
                        label-for="filterInput4"
                        class="mt-1"
                    >
                        <b-input-group size="sm">
                            <b-form-input
                                id="filterInput4"
                                v-model="filter4"
                                type="search"
                                placeholder="Buscar ..."
                            />
                            <b-input-group-append>
                                <b-button
                                    :disabled="!filter4"
                                    @click="filter4 = ''"
                                >
                                    Limpiar
                                </b-button>
                            </b-input-group-append>
                        </b-input-group>
                    </b-form-group>

                    <json-excel :data="seventTableExport()" name="certificados_emitidos_formularios_de_revisiones_laborales.xls"></json-excel>
                    <b-table
                        ref="table4"
                        striped
                        hover
                        responsive
                        class="position-relative text-center"
                        :per-page="perPage4"
                        :current-page="currentPage4"
                        :items="items4"
                        :fields="fields4"
                        :sort-by.sync="sortBy4"
                        :sort-desc.sync="sortDesc4"
                        :sort-direction="sortDirection4"
                        :filter="filter4"
                        :filter-included-fields="filterOn4"
                        @filtered="onFiltered4"
                        show-empty
                    >

                        <template #head()="data">
                            <div v-html="data.label"></div>
                        </template>

                        <template #empty="scope">
                            NO HAY DATA DISPONIBLE
                        </template>

                        <template #cell(id)="data">
                            <span class="text-nowrap">
                                {{ data.index+1 }}
                            </span>
                        </template>

                        <template #cell(proveedor)="data">
                            {{ data.item.proveedor.toUpperCase() }}                   
                        </template>

                        <template #cell(fecha_emision)="data">
                            <span v-if="data.item.fecha_emision">{{ data.item.fecha_emision }}</span>
                            <span v-else>---</span>                   
                        </template>
    
                    </b-table>

                </b-card>
            </b-col>
        </b-row>
    </div>  
</template>

<script>

    import {Spanish as SpanishLocale} from 'flatpickr/dist/l10n/es.js';
    import flatPickr from 'vue-flatpickr-component';
    import VueApexCharts from 'vue-apexcharts';
    import JsonExcel from "./download-excel";
    import axios from 'axios';

    export default {
        components: {
            VueApexCharts,
            flatPickr,
            JsonExcel
        },
        data() {
            return {
                perPage1: 50,
                pageOptions1: [10, 15, 20, 25, 30],
                totalRows1: 1,
                currentPage1: 1,
                sortBy1: '',
                sortDesc1: false,
                sortDirection1: 'asc',
                filter1: null,   
                filterOn1: [],
                fields1: [],
                items1: [],
                
                perPage2: 50,
                pageOptions2: [10, 15, 20, 25, 30],
                totalRows2: 1,
                currentPage2: 1,
                sortBy2: '',
                sortDesc2: false,
                sortDirection2: 'asc',
                filter2: null,   
                filterOn2: [],     
                fields2: [],
                items2: [],
                
                perPage3: 50,
                pageOptions3: [10, 15, 20, 25, 30],
                totalRows3: 1,
                currentPage3: 1,

                sortBy3: '',
                sortDesc3: false,
                sortDirection3: 'asc',
                filter3: null,   
                filterOn3: [],
                fields3: [],
                items3: [],

                // ======================

                perPage4: 50000,
                pageOptions4: [10, 15, 20, 25, 30],
                totalRows4: 1,
                currentPage4: 1,

                sortBy4: '',
                sortDesc4: false,
                sortDirection4: 'asc',
                filter4: null,   
                filterOn4: [],
                fields4: [{
                    key: 'id',
                    label: 'ITEM',
                    sortable: true
                },
                {
                    key: 'proveedor',
                    label: 'PROVEEDOR',
                    sortable: true
                },
                {
                    key: 'rubro',
                    label: 'RUBRO',
                    sortable: true
                },
                {
                    key: 'mes_anno',
                    label: 'MES/AÑO DE ASIGNACIÓN',
                    sortable: true
                },
                {
                    key: 'fecha_inicio',
                    label: 'FECHA DE INICIO',
                    sortable: true
                },
                {
                    key: 'fecha_emision',
                    label: 'FECHA DE EMISIÓN',
                    sortable: true
                },
                {
                    key: 'puntaje',
                    label: 'PUNTAJE OBTENIDO',
                    sortable: true
                },
                {
                    key: 'nivel',
                    label: 'NIVEL OBTENIDO',
                    sortable: true
                }],
                items4: [],

                // ======================

                totalOne: "",
                totalTwo: "",
                totalThree: "",
                totalQuarter: "",
                totalQuinto: "",
                starDateOne: "",
                endDateOne: "",
                starDateTwo: "",
                endDateTwo: "",
                starDateQuarter: "",
                endDateQuarter: "",
                starDateQuinto: "",
                endDateQuinto: "",
                starDateSix: "",
                endDateSix: "",
                starDateSeven: "",
                endDateSeven: "",
                configCalendar: {
                    locale:  SpanishLocale,
                    dateFormat: 'd-m-Y'
                },
                       
                fieldsThree: [
                    {key: 'index', label: "#"},
                    {key: "business_area_name", label: "Rubro"},
                    {key: "quantity", label: "Cantidad"},
                    {key: "percentage", label: "%"}
                ],
                itemsThree: [],
                fieldsQuarter: [
                    {key: 'index', label: "#"},
                    {key: "form_state_name", label: "ESTADO DEL FORMULARIO"},
                    {key: "quantity", label: "Cantidad"},
                    {key: "percentage", label: "%"}
                ],
                itemsQuarter: [],
                fieldsQuinto: [
                    {key: 'index', label: "#"},
                    {key: "level_name", label: "NIVEL OBTENIDO"},
                    {key: "quantity", label: "Cantidad"},
                    {key: "percentage", label: "%"}
                ],
                itemsQuinto: [],
                tablePieOne: {
                    series: [],
                    chartOptions: {
                        chart: {
                            toolbar: {
                                show: false
                            }
                        },
                        labels: [],
                        dataLabels: {
                            enabled: true
                        },
                        legend: { show: false },
                        stroke: {
                            width: 4
                        },
                        noData: { text: "Sin datos..." }
                    }
                },
                tablePieTwo: {
                    series: [],
                    chartOptions: {
                        chart: {
                            toolbar: {
                                show: false
                            }
                        },
                        labels: [],
                        dataLabels: {
                            enabled: true
                        },
                        legend: { show: false },
                        stroke: {
                            width: 4,
                        },
                        noData: { text: "Sin datos..." }
                    }
                },
                tablePieThree: {
                    series: [],
                    chartOptions: {
                        chart: {
                            toolbar: {
                                show: false,
                            }
                        },
                        labels: [],
                        dataLabels: {
                            enabled: true
                        },
                        legend: { show: false },
                        stroke: {
                            width: 4
                        },
                        noData: { text: "Sin datos..." }
                    }
                },
                tablePieQuarter: {
                    series: [],
                    chartOptions: {
                        chart: {
                            toolbar: {
                                show: false,
                            }
                        },
                        labels: [],
                        dataLabels: {
                            enabled: true
                        },
                        legend: { show: false },
                        stroke: {
                            width: 4
                        },
                        noData: { text: "Sin datos..." }
                    }
                },
                tablePieQuinto: {
                    chartOptions: {
                        chart: {
                            type: 'bar',
                            toolbar: {
                                show: false
                            }
                        },
                        dataLabels: {
                            enabled: true
                        },
                        legend: { show: false },
                        stroke: {
                            width: 4
                        },
                        noData: { text: "Sin datos..." }
                    },
                    series: [{
                        name: 'Cantidad',
                        data: []
                    }]
                },
            }
        },
        computed: {
            sortOption1(){
                 return this.fields1
                    .filter(f => f.sortable)
                    .map(f => ({ text: f.label, value: f.key }));
            },           
            sortOption2(){
                 return this.fields2
                    .filter(f => f.sortable)
                    .map(f => ({ text: f.label, value: f.key }));
            },  
            sortOptions3() {
                // Create an options list from our fields
                return this.fields3
                    .filter(f => f.sortable)
                    .map(f => ({ text: f.label, value: f.key }));
            },
            sortOptions4() {
                // Create an options list from our fields
                return this.fields4
                    .filter(f => f.sortable)
                    .map(f => ({ text: f.label, value: f.key }));
            }
        },
        beforeMount() {
            this.getDataTableOne();
            this.getDataTableTwo();
            this.getDataTableThree();
            this.getDataTableQuarter();
            this.getDataTableQuinto();
            this.getDataTableSexto();
            this.getDataTableSeptimo();
        },
        methods: {
            async getDataTableOne(){
                await axios
                    .get(`${process.env.VUE_APP_HOST}/api/dashboard/reporteria/table-1?filterStartDate=${this.starDateOne}&filterEndDate=${this.endDateOne}`)
                    .then(response => {
                        if(response){
                                                                                   
                            this.fields1 = response.data.fields;
                            this.items1 = response.data.data; 
                           
                            this.totalRows1 = response.data.data.length;
                            this.currentPage1 = 1;
                            this.sortBy1 = '';
                            this.sortDesc1 = false;
                            this.sortDirection1 = 'asc';
                            this.filter1 = null;
                        }
                    });
            },
            async getDataTableTwo(){
                await axios
                    .get(`${process.env.VUE_APP_HOST}/api/dashboard/reporteria/table-2?filterStartDate=${this.starDateTwo}&filterEndDate=${this.endDateTwo}`)
                    .then(response => {
                        if(response){
                                                                                   
                            this.fields2 = response.data.fields;
                            this.items2 = response.data.data; 
                            this.totalRows2 = response.data.data.length;
                            this.currentPage2 = 1;
                            this.sortBy2 = '';
                            this.sortDesc2 = false;
                            this.sortDirection2 = 'asc';
                            this.filter2 = null;
                        }
                    })
            },
            async getDataTableThree (){
                let response = await axios .get(`${process.env.VUE_APP_HOST}/api/dashboard/reporteria/business-areas-providers`);

                let total = 0;
                response.data.map(item => { total = total + item.providers_count } );
                                
                response.data.map( (item, index) => {
                    this.itemsThree.push({
                        index: index + 1,
                        business_area_name: item.name,
                        quantity: item.providers_count,
                        percentage: ((item.providers_count*100)/total).toFixed(2) + '%',
                    });     
                    this.tablePieThree.series.push((item.providers_count*100)/total);
                    this.tablePieThree.chartOptions.labels.push(item.name)
                });
                
                this.itemsThree.push({
                    index: '',
                    business_area_name: 'TOTAL',
                    quantity: total,
                    percentage: '100%',
                    status: true
                });
                this.totalThree = total;       

            },
            async getDataTableQuarter (){

                let response = await axios .get(`${process.env.VUE_APP_HOST}/api/dashboard/reporteria/states-forms?filterStartDate=${this.starDateQuarter}&filterEndDate=${this.endDateQuarter}`);
                
                let total = 0;
                response.data.map(item => { total = total + item.forms_count } );
                                
                this.itemsQuarter = [];
                this.tablePieQuarter.series = [];
                let labels = [];

                response.data.map( (item, index) => {

                    this.itemsQuarter.push({
                        index: index + 1,
                        form_state_name: item.name,
                        quantity: item.forms_count,
                        percentage: ((item.forms_count*100)/total).toFixed(2) + '%'
                    });

                    this.tablePieQuarter.series.push((item.forms_count*100)/total);
                    labels.push(item.name);

                });

                this.itemsQuarter.push({
                    index: '',
                    form_state_name: 'TOTAL',
                    quantity: total,
                    percentage: '100%',
                    status: true
                });
                
                this.totalQuarter = total;
                this.tablePieQuarter.chartOptions.labels = labels;
                await this.$refs["pieQuarterRef"].refresh();

            },
            async getDataTableQuinto (){

                let response = await axios .get(`${process.env.VUE_APP_HOST}/api/dashboard/reporteria/levels-forms?filterStartDate=${this.starDateQuinto}&filterEndDate=${this.endDateQuinto}`);
               
                let total = 0;
                response.data.map(item => { total = total + item.forms_count } );
                                
                this.itemsQuinto = [];
                this.tablePieQuinto.series[0].data = [];

                response.data.map( (item, index) => {

                    this.itemsQuinto.push({
                        index: index + 1,
                        level_name: item.name,
                        quantity: item.forms_count,
                        percentage: ((item.forms_count*100)/total).toFixed(2) + '%'
                    });

                    this.tablePieQuinto.series[0].data.push({
                        x: item.name,
                        y: item.forms_count
                    });

                });

                this.itemsQuinto.push({
                    index: '',
                    level_name: 'TOTAL',
                    quantity: total,
                    percentage: '100%',
                    status: true
                });
                
                this.totalQuinto = total;
                await this.$refs["pieQuintoRef"].refresh();
            },
            async getDataTableSexto (){

                let response = await axios .get(`${process.env.VUE_APP_HOST}/api/dashboard/reporteria/table-3?filterStartDate=${this.starDateSix}&filterEndDate=${this.endDateSix}`);
                
                this.fields3 = response.data.fields;
                this.items3 = response.data.data;

                this.totalRows3 = response.data.data.length;

                this.currentPage3 = 1;
                this.sortBy3 = '';
                this.sortDesc3 = false;
                this.sortDirection3 = 'asc';
                this.filter3 = null;
            },
            async getDataTableSeptimo (){

                let response = await axios .get(`${process.env.VUE_APP_HOST}/api/dashboard/reporteria/table-4?filterStartDate=${this.starDateSeven}&filterEndDate=${this.endDateSeven}`);
            
                this.items4 = response.data;

                this.totalRows4 = response.data.length;

                this.currentPage4 = 1;
                this.sortBy4 = '';
                this.sortDesc4 = false;
                this.sortDirection4 = 'asc';
                this.filter4 = null;
            },
            async filterOne(){
                await this.getDataTableOne();
                this.$refs['table1Ref'].refresh();
            },
            async filterTwo(){
               await this.getDataTableTwo();
                this.$refs['table2Ref'].refresh();
            },
            async filterQuarter (){
                await this.getDataTableQuarter();
                this.$refs['table-quarter-ref'].refresh();
            },
            async filterQuinto (){
                await this.getDataTableQuinto();
                this.$refs['table-quinto-ref'].refresh();
            },
            async filterSix (){
                await this.getDataTableSexto();
                this.$refs['table3'].refresh();
            },
            async filterSeven (){
                await this.getDataTableSeptimo();
                this.$refs['table4'].refresh();
            },
            firstTableExport (){

                let array = [];

                for (let a = 0; a < this.items1.length; a++)
                {
                    let objBody = {
                        'ITEM': (a+1 == this.items1.length ? '' : a+1),
                        'CANTIDAD FORMULARIOS POR RUBRO Y POR PROCESO': this.items1[a]["business_area_name"],
                        'TOTAL': this.items1[a]["total"],
                        'COMPLETADO': this.items1[a]["completado_cantidad"],
                        'COMPLETADO (%)': this.items1[a]["completado_porcentaje"],
                        'EN CURSO': this.items1[a]["en_curso_cantidad"],
                        'EN CURSO (%)': this.items1[a]["en_curso_porcentaje"],
                        'POR REALIZAR': this.items1[a]["por_realizar_cantidad"],
                        'POR REALIZAR (%)': this.items1[a]["por_realizar_porcentaje"],
                        'NO REALIZADO': this.items1[a]["no_realizado_cantidad"],
                        'NO REALIZADO (%)': this.items1[a]["no_realizado_porcentaje"],
                        'EN REVISION': this.items1[a]["en_revisión_cantidad"],
                        'EN REVISION (%)': this.items1[a]["en_revisión_porcentaje"]
                    };

                    array.push(objBody);
                }

                return array;
            },
            secondTableExport(){

                let array = [];

                for (let a=0; a<this.items2.length; a++)
                {
                    let objBody = {
                        'ITEM': (a+1 == this.items2.length ? '' : a+1),
                        'RUBRO': this.items2[a]["business_area_name"],
                        'CANTIDAD': this.items2[a]["total"],
                        'PORCENTAJE':  this.items2[a]["percentage"]
                    };

                    array.push(objBody);
                }
                
                return array;
            },
            threeTableExport (){

                let array = [];

                for (let a=0; a<this.itemsThree.length; a++)
                {
                    let objBody = {
                        'ITEM': a+1,
                        'RUBRO': this.itemsThree[a]["business_area_name"],
                        'CANTIDAD': this.itemsThree[a]["quantity"],
                        'PORCENTAJE':  this.itemsThree[a]["percentage"]
                    };

                    array.push(objBody);
                }
                
                array.splice(array.length-1, 1);

                let objFoot = {
                    'ITEM': '',
                    'RUBRO': 'TOTAL',
                    'CANTIDAD': this.totalThree,
                    'PORCENTAJE': '100%'
                };

                array.push(objFoot);
                
                return array;
            },
            quarterTableExport (){

                let array = [];

                for (let a=0; a<this.itemsQuarter.length; a++)
                {
                    let objBody = {
                        'ITEM': a+1,
                        'ESTADO DEL FORMULARIO': this.itemsQuarter[a]["state_form_name"],
                        'CANTIDAD': this.itemsQuarter[a]["quantity"],
                        'PORCENTAJE':  this.itemsQuarter[a]["percentage"]
                    };

                    array.push(objBody);
                }
                
                array.splice(array.length-1, 1);

                let objFoot = {
                    'ITEM': '',
                    'ESTADO DEL FORMULARIO': 'TOTAL',
                    'CANTIDAD': this.totalQuarter,
                    'PORCENTAJE': '100%'
                };

                array.push(objFoot);
                
                return array;
            },
            quintoTableExport (){
                
                let array = [];

                for (let a=0; a<this.itemsQuinto.length; a++)
                {
                    let objBody = {
                        'ITEM': a+1,
                        'NIVEL OBTENIDO': this.itemsQuinto[a]["level_name"],
                        'CANTIDAD': this.itemsQuinto[a]["quantity"],
                        'PORCENTAJE':  this.itemsQuinto[a]["percentage"]
                    };

                    array.push(objBody);
                }
                
                array.splice(array.length-1, 1);

                let objFoot = {
                    'ITEM': '',
                    'NIVEL OBTENIDO': 'TOTAL',
                    'CANTIDAD': this.totalQuinto,
                    'PORCENTAJE': '100%'
                };

                array.push(objFoot);
                
                return array;
            },
            onFiltered1(filteredItems) {
                this.totalRows1 = filteredItems.length;
                this.currentPage1 = 1;
            },
            onFiltered2(filteredItems) {
                this.totalRows2 = filteredItems.length;
                this.currentPage2 = 1;
            },
            onFiltered3(filteredItems) {
                this.totalRows3 = filteredItems.length;
                this.currentPage3 = 1;
            },
            onFiltered4(filteredItems) {
                this.totalRows4 = filteredItems.length;
                this.currentPage4 = 1;
            },
            sixTableExport (){

                let array = [];

                for (let a = 0; a < this.items3.length; a++)
                {
                    let objBody = {
                        'ITEM': (a+1 == this.items3.length ? '' : a+1),
                        'CANTIDAD FORMULARIOS COMPLETADOS POR RUBRO': this.items3[a]["business_area_name"],
                        'TOTAL': this.items3[a]["total"],
                        'ALTO': this.items3[a]["alto_cantidad"],
                        'ALTO (%)': this.items3[a]["alto_porcentaje"],
                        'MEDIO': this.items3[a]["medio_cantidad"],
                        'MEDIO (%)': this.items3[a]["medio_porcentaje"],
                        'BAJO': this.items3[a]["bajo_cantidad"],
                        'BAJO (%)': this.items3[a]["bajo_porcentaje"]
                        //'SOBRESALIENTE': this.items3[a]["sobresaliente_cantidad"],
                        //'SOBRESALIENTE (%)': this.items3[a]["sobresaliente_porcentaje"],
                        //'BUENO': this.items3[a]["bueno_cantidad"],
                        //'BUENO (%)': this.items3[a]["bueno_porcentaje"],
                        //'REGULAR': this.items3[a]["regular_cantidad"],
                        //'REGULAR (%)': this.items3[a]["regular_porcentaje"],
                        //'REQUIERE MEJORAR': this.items3[a]["requiere_mejorar_cantidad"],
                        //'REQUIERE MEJORAR (%)': this.items3[a]["requiere_mejorar_porcentaje"],
                        //'NO APTO': this.items3[a]["no_apto_cantidad"],
                        //'NO APTO (%)': this.items3[a]["no_apto_porcentaje"]
                    };

                    array.push(objBody);
                }

                return array;
            },
            seventTableExport (){

                let array = [];

                for (let a = 0; a < this.items4.length; a++)
                {
                    let objBody = {
                        'ITEM': a+1,
                        'PROVEEDOR': this.items4[a]["proveedor"].toUpperCase(),
                        'RUBRO': this.items4[a]["rubro"],
                        'MES/ANNO DE ASIGNACION': this.items4[a]["mes_anno"],
                        'FECHA DE INICIO': this.items4[a]["fecha_inicio"],
                        'FECHA DE EMISION': this.items4[a]["fecha_emision"] ? this.items4[a]["fecha_emision"] : '---',
                        'PUNTAJE OBTENIDO': this.items4[a]["puntaje"],
                        'NIVEL OBTENIDO': this.items4[a]["nivel"]
                    };

                    array.push(objBody);
                }

                return array;
            }
        }
    }

</script>

<style scoped>

    input[type=text] {
        border: none;
        border-radius: 0px;
        background: transparent;
        border-bottom: 1px solid #d8d6de;
    }

</style>

<style lang="scss">
    @import '@core/scss/vue/libs/vue-flatpicker.scss';
    @import '@core/scss/vue/libs/chart-apex.scss';
</style>
